<template>
  <div>
    <b-navbar toggleable="lg" type="dark">

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item  @click="goToPage('/home', 'home')" :active="currentScreen === 'home'">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-col>
              <img class="icon-img" src="@/assets/home-white.svg" alt="" v-if="currentScreen !== 'home'">
              <img class="icon-img" src="@/assets/home-solid.svg" alt="" v-if="currentScreen === 'home'">
            </b-col>
          </b-row>

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Home</span>
            </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <img class="icon-img mx-auto" src="@/assets/home-white.svg" alt="" v-if="currentScreen !== 'home'">
              <img class="icon-img mx-auto" src="@/assets/home-solid.svg" alt="" v-if="currentScreen === 'home'">
              <span class="ml-3">Home</span>
          </b-row>
        </b-nav-item>
        <b-nav-item @click="goToPage('/summary', 'summary')" :active="currentScreen === 'summary'">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-col>
              <img class="icon-img" src="@/assets/chart-area-white.svg" alt="" v-if="currentScreen !== 'summary'">
              <img class="icon-img" src="@/assets/chart-area-solid.svg" alt="" v-if="currentScreen === 'summary'">
            </b-col>
          </b-row>

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Summary</span>
            </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <img class="icon-img mx-auto" src="@/assets/chart-area-white.svg" alt="" v-if="currentScreen !== 'summary'">
              <img class="icon-img mx-auto" src="@/assets/chart-area-solid.svg" alt="" v-if="currentScreen === 'summary'">
              <span class="ml-3">Summary</span>
          </b-row>
        </b-nav-item>
        <b-nav-item  @click="goToPage('/solar', 'solar')" :active="currentScreen === 'solar'">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-col>
              <img class="icon-img" src="@/assets/solar-panel-white.svg" alt="" v-if="currentScreen !== 'solar'">
              <img class="icon-img" src="@/assets/solar-panel-solid.svg" alt="" v-if="currentScreen === 'solar'">
            </b-col>
          </b-row>

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Solar</span>
            </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <img class="icon-img mx-auto" src="@/assets/solar-panel-white.svg" alt="" v-if="currentScreen !== 'solar'">
              <img class="icon-img mx-auto" src="@/assets/solar-panel-solid.svg" alt="" v-if="currentScreen === 'solar'">
              <span class="ml-3">Solar</span>
          </b-row>
        </b-nav-item>
        <b-nav-item  @click="goToPage('/generator', 'generator')" :active="currentScreen === 'generator'">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-col>
              <img class="icon-img" src="@/assets/charging-station-white.svg" alt="" v-if="currentScreen !== 'generator'">
              <img class="icon-img" src="@/assets/charging-station-solid.svg" alt="" v-if="currentScreen === 'generator'">
            </b-col>
          </b-row>

          <b-row class="mt-0 normal-menu" align-h="center">
            <span>Generator</span>
          </b-row>

          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <img class="icon-img mx-auto" src="@/assets/charging-station-white.svg" alt="" v-if="currentScreen !== 'generator'">
              <img class="icon-img mx-auto" src="@/assets/charging-station-solid.svg" alt="" v-if="currentScreen === 'generator'">
              <span class="ml-3">Generator</span>
          </b-row>
        </b-nav-item>
        <b-nav-item  @click="goToPage('/grid', 'grid')" :active="currentScreen === 'grid'">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-col>
              <img class="icon-img" src="@/assets/bolt-white.svg" alt="" v-if="currentScreen !== 'grid'">
              <img class="icon-img" src="@/assets/bolt-solid.svg" alt="" v-if="currentScreen === 'grid'">
            </b-col>
          </b-row>

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Grid</span>
            </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <img class="icon-img mx-auto" src="@/assets/bolt-white.svg" alt="" v-if="currentScreen !== 'grid'">
              <img class="icon-img mx-auto" src="@/assets/bolt-solid.svg" alt="" v-if="currentScreen === 'grid'">
              <span class="ml-3">Grid</span>
          </b-row>
        </b-nav-item>
        <b-nav-item  @click="goToPage('/battery', 'battery')" :active="currentScreen === 'battery'">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-col>
              <img class="icon-img" src="@/assets/car-battery-white.svg" alt="" v-if="currentScreen !== 'battery'">
              <img class="icon-img" src="@/assets/car-battery-solid.svg" alt="" v-if="currentScreen === 'battery'">
            </b-col>
          </b-row>

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Battery</span>
            </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <img class="icon-img mx-auto" src="@/assets/car-battery-white.svg" alt="" v-if="currentScreen !== 'battery'">
              <img class="icon-img mx-auto" src="@/assets/car-battery-solid.svg" alt="" v-if="currentScreen === 'battery'">
              <span class="ml-3">Battery</span>
          </b-row>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right class="stacked-menu" :active="currentScreen === 'settings'" no-caret>
          <!-- Using 'button-content' slot -->
          <!-- <button role="button" type="button" class="btn" data-toggle="dropdown"> 
    Dropdown Without Arrow
</button> -->
          <template #button-content>
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
            <img class="icon-img mx-auto" src="@/assets/cog-solid.svg" alt="" v-if="currentScreen !== 'settings'">
            <img class="icon-img mx-auto" src="@/assets/cog-white.svg" alt="" v-if="currentScreen === 'settings'">
            <span class="ml-3">Settings</span>
          </b-row>
          </template>
          <!-- <b-dropdown-item  @click="goToPage('/summary')">Profile</b-dropdown-item> -->
          <b-dropdown-item @click="goToPage('/settings', 'settings')">
            Settings
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="showModal()">
            <b-icon-power></b-icon-power>
            Sign Out
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown v-if="locationSelected !== null && loadingLocations === false" :text="locationSelected.untName !== null? locationSelected.untName : 'Not available'" right class="custom-drop">
          <b-dropdown-item v-for="(item, index) in locationsListed" :key="index" @click="changeSelectedLocation(index)" class="custom-menu-item">
            {{item.untName}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item class="custom-drop" v-if="loadingLocations === true">
          <b-row class="mt-0 normal-menu" align-h="center">
            <b-spinner label="Spinning"></b-spinner>
          </b-row>
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <b-spinner label="Spinning"></b-spinner>
          </b-row>
        </b-nav-item>
        <b-nav-item-dropdown right class="pl-4 pr-2 normal-menu" :active="currentScreen === 'settings'">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <img class="icon-img settings-img" src="@/assets/cog-solid.svg" alt="" v-if="currentScreen !== 'settings'">
            <img class="icon-img settings-img" src="@/assets/cog-white.svg" alt="" v-if="currentScreen === 'settings'">
          </template>
          <!-- <b-dropdown-item  @click="goToPage('/summary')">Profile</b-dropdown-item> -->
          <b-dropdown-item @click="goToPage('/settings', 'settings')">
            Settings
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="showModal()">
            <b-icon-power></b-icon-power>
            Sign Out
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
    <router-view/>
    
  <b-modal id="modal-1" hide-footer>
    <b-row>
      <b-col cols="12" class="text-center"><p>Are you sure you want to sign out?</p></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-center">
        <b-button @click="hideModal" class="mr-2 ml-2">No</b-button>
        <b-button variant="primary" @click="logout" class="mr-2 ml-2">Yes</b-button>
      </b-col>
    </b-row>
    <div class="d-block"></div>
  </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from './main'

export default {
  data: () => ({
    loadingLocations: false
  }),
  created(){
    bus.$on('changedLocation', () => {
      this.loadingLocations = true
      setTimeout(function(){ 
        bus.$emit('finishLoad')
      }, 100);
    })
    bus.$on('finishLoad', () => {
      this.loadingLocations = false
    })
    this.$store.commit('setApiUrl', this.$root.urlConfig)
  },
  methods: {
    goToPage(routePath, location){
      this.$router.push({path: routePath})
      this.$store.commit('changeScreen', location)
    },
      showModal() {
        this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
      },
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
      },
      logout() {
        this.$store.commit('clearUserInfo')
        this.$router.push({path: '/'})
      },
      changeSelectedLocation(index){
        let listedLocations = this.locationsListed
        if (listedLocations.length > 0) {
          this.$store.commit('changeLocation', listedLocations[index])
          console.log('locastion', listedLocations[index])
          bus.$emit('changedLocation')
        }
      }
  },
    computed:{
        ...mapState(['locations', 'selectedLocation', 'currentScreen']),
        locationsListed(){
          if (this.locations.length > 0) {
            return this.locations
          }
          else{
            let locationsList = JSON.parse(localStorage.getItem('locations'))
            this.$store.commit('setLocationsAfterReload', locationsList)
            return locationsList
          }
        },
        locationSelected(){
          if (this.selectedLocation.untId === null) {
            return {
                untId: localStorage.getItem('selectedLocationId') === 'null' ? null : localStorage.getItem('selectedLocationId'),
                untName: localStorage.getItem('selectedLocationName') === 'null' ? null : localStorage.getItem('selectedLocationName')
              }
          }
          else{
            return this.selectedLocation
          }
          
        }
    }
}
</script>
